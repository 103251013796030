<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <status-geral
          v-if="bdgdVersaoId"
          :bdgdVersaoId="bdgdVersaoId"
        />
        <dashboard-brr v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StatusGeral from '@/components/geracao-bdgd/validacoes-bdgd/StatusGeralBdgd';
import DashboardBrr from '@/components/general/graficos/DashboardBrr';

export default {
  name: 'DashboardBdgd',
  components: {
    StatusGeral,
    DashboardBrr
  },
  computed: {
    bdgdVersaoId() {
      const { getUserSelectedCompanyBdgdVersion } = this.$store.getters;
      if (getUserSelectedCompanyBdgdVersion) {
        return getUserSelectedCompanyBdgdVersion.id;
      }
      return null;
    }
  }
};
</script>
